<template>
  <div class="flex flex-row w-full justify-center">
    <div class="flex flex-col text-left max-w-xl">
      <el-input placeholder="请输入账号" v-model="userName" class="my-6" size="large">
        <template #prepend><el-icon><user /></el-icon></template>
      </el-input>

      <el-input placeholder="请输入密码" v-model="userPass" type="password"
        :show-password="true" class="mb-6" size="large">
        <template #prepend><el-icon><lock /></el-icon></template>
      </el-input>

      <drag-verify
        ref="dragVerify"
        :width="300"
        v-model:isPassing="verified"
        text="请按住滑块拖动"
        successText="验证通过"
        background="#ccc"
        handlerBg="#ffa500"
        completedBg="rgb(105, 231, 251)"
        handlerIcon="el-icon-d-arrow-right"
        successIcon="el-icon-circle-check"
      />

      <div :class="`btn w-full ${isValid ? 'cursor-pointer' : 'cursor-not-allowed'}`"
        @click="onLogin">
        登录
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import {useRouter} from 'vue-router'
import * as storeUser from './store'
import * as storeBag from '@/lib/store/bag'
import {User, Lock} from '@element-plus/icons-vue'
import dragVerify from '@/components/common/dragVerify'

export default {
  components: {
    User,
    Lock,
    dragVerify,
  },
  setup() {
    const router = useRouter();
    const userName = ref('');
    const userPass = ref('');
    const verified = ref(false);

    const isValid = computed(function() {
      return userName.value && userPass.value && verified.value;
    });

    const onLogin = async () => {
      if (!isValid.value) {
        return;
      }

      await storeUser.actions.login({
        userName: userName.value,
        userPass: userPass.value,
      });

      const fromUri = storeBag.state.routeParams && storeBag.state.routeParams.from || '/';
      if (storeUser.state.userInfo && fromUri) {
        router.push(fromUri).catch(() => {});
      }
    };

    return {
      userName,
      userPass,
      verified,
      isValid,
      onLogin,
    };
  },
}
</script>

<style scoped>
.btn {
  @apply text-black;
  @apply rounded;
  @apply my-8;
  @apply py-2;
  @apply text-center;
  border: 1px solid theme('colors.orange.500');
}
.btn:hover {
  @apply bg-orange-500;
}
</style>